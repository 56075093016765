<template>
  <GmapMap ref="googleMap" :center="mapConfig.center" :zoom="mapConfig.zoom" class="google-map" />
</template>
<script>
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { gmapApi as googleMapsApi } from 'vue2-google-maps';

export default {
  props: {
    mapConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    apiKey: { type: String, default: '' },
    schools: {
      type: Array,
      default() {
        return [];
      },
    },
    selected: {
      type: Object,
      default() {
        return { location: this.mapConfig.center };
      },
    },
  },
  data() {
    return {
      map: null,
      showDescription: false,
      markers: [],
      clusterer: null,
      currentMarkers: [],
    };
  },
  computed: {
    google: googleMapsApi,
    mapReady() {
      return this.google && this.map !== null;
    },
    allSchools() {
      return this.schools;
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch: {
    mapReady(value) {
      if (!value) return;
      this.plotMarkers();
    },
    allSchools() {
      this.plotMarkers();
    },
    selected(newVal) {
      this.map.setCenter(newVal.location);
      this.map.setZoom(16);
    },
  },
  async mounted() {
    this.$refs.googleMap.$mapPromise.then((map) => {
      this.map = map;
      this.$store.dispatch('setMap', map);
      this.$store.dispatch('setGoogle', googleMapsApi);
      const div = document.createElement('div');
      div.innerHTML = `<div style="margin-right:23px;margin-top:27px;background:#FFFFFF" class="default-text">
		    <div class="d-flex">
        <div class="d-flex flex-column">
		      <div class="d-flex pa-3">
		        <div style="width:15px;height:15px;background:#6CACE4;border-radius: 2px;"></div>
		        <div class="pl-2 label-text ${this.mobile ? 'mobile-label' : ''}">Incentive school</div>
		      </div>
		      <div class="d-flex pa-3 pt-0">
		        <div style="width:15px;height:15px;background: #D7153A;border-radius: 2px;"></div>
		        <div class="pl-2 label-text ${this.mobile ? 'mobile-label' : ''}">Non-incentive school</div>
		      </div>
		    </div>
        <div class="align-self-start pa-3">
          <div class="infoicon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 19V17H13V19H11ZM14.17 12.17L15.07 11.25C15.64 10.68 16 9.88 16 9C16 6.79 14.21 5 12 5C9.79 5 8 6.79 8 9H10C10 7.9 10.9 7 12 7C13.1 7 14 7.9 14 9C14 9.55 13.78 10.05 13.41 10.41L12.17 11.67C11.45 12.4 11 13.4 11 14.5V15H13C13 13.5 13.45 12.9 14.17 12.17Z" fill="#002664"/>
        </svg></div>
        <div class="body-1" style="color#fff;">Selected rural and remote NSW public schools that attract additional benefits and incentives.</div>
        </div></div></div>`;
      this.map.controls[this.google.maps.ControlPosition.RIGHT_TOP].push(div);
    });
    this.$refs.googleMap.options.mapTypeControl = false;
  },
  methods: {
    plotMarkers() {
      const filteredSchools = this.schools.filter((item) => item.lat !== null);
      this.markers = filteredSchools.map((school) => {
        let markerProperties;
        if (school.schoolFullName.length <= 18) {
          markerProperties = {
            size: '1',
            width: 231,
            pointLat: 65,
            pointLng: 30,
          };
        } else if (school.schoolFullName.length > 18 && school.schoolFullName.length <= 40) {
          markerProperties = {
            size: '2',
            width: this.mobile ? 231 : 300,
            pointLat: this.mobile ? 65 : 150,
            pointLng: 30,
          };
        } else
          markerProperties = {
            size: '3',
            width: this.mobile ? 300 : 350,
            pointLat: this.mobile ? 150 : 175,
            pointLng: 30,
          };
        const image = school.isIncentive
          ? `./markers/incentive${this.mobileMarkers(markerProperties.size)}.png`
          : `./markers/non_incentive${this.mobileMarkers(markerProperties.size)}.png`;
        const marker = new this.google.maps.Marker({
          position: {
            lat: +school.lat,
            lng: +school.lng,
          },
          icon: {
            url: image,
            // This marker is 20 pixels wide by 32 pixels high.
            size: new this.google.maps.Size(markerProperties.width, 60),
            // The origin for this image is (0, 0).
            origin: new this.google.maps.Point(0, -14),
            // The anchor for this image is the base of the flagpole at (0, 32).
            anchor: new this.google.maps.Point(markerProperties.pointLat, markerProperties.pointLng),
          },
          label: {
            text: school.schoolFullName,
            fontFamily: 'Public Sans',
            fontWeight: '700',
            fontSize: this.mobile ? '14px' : '16px',
          },
        });
        marker.addListener('click', () => {
          this.showInfo(school);
        });
        return marker;
      });
      if (this.clusterer) {
        this.refreshMarkers();
      }
      if (!this.clusterer) {
        this.clusterer = new MarkerClusterer(this.map, [], {
          imagePath: './markers/m',
          enableRetinaIcons: true,
          maxZoom: 20,
          minimumClusterSize: 2,
          gridSize: 100,
          styles: [
            {
              anchorText: [11, 20],
              anchorIcon: [20, 40],
              textColor: 'white',
              fontFamily: 'Public Sans',
              fontWeight: '700',
              textSize: this.mobile ? 14 : 16,
              height: 40,
              width: 40,
              url: './markers/m1.png',
            },
            {
              anchorText: [21, 29],
              anchorIcon: [29, 59],
              textColor: 'white',
              fontFamily: 'Public Sans',
              fontWeight: '700',
              textSize: this.mobile ? 14 : 16,
              height: 59,
              width: 59,
              url: './markers/m2.png',
            },
            {
              anchorText: [35, 44],
              anchorIcon: [44, 89],
              textColor: 'white',
              fontFamily: 'Public Sans',
              fontWeight: '700',
              textSize: this.mobile ? 14 : 16,
              height: 89,
              width: 89,
              url: './markers/m3.png',
            },
            {
              anchorText: [58, 66],
              anchorIcon: [66, 133],
              textColor: 'white',
              fontFamily: 'Public Sans',
              fontWeight: '700',
              textSize: this.mobile ? 14 : 16,
              height: 133,
              width: 133,
              url: './markers/m4.png',
            },
          ],
        });
      }
      this.google.maps.event.addListener(this.map, 'tilesloaded', () => {
        this.refreshMarkers();
      });
    },
    refreshMarkers() {
      this.clusterer.clearMarkers();
      this.currentMarkers = this.markers.filter(
        (marker) => marker.getVisible() && this.map.getBounds().contains(marker.getPosition())
      );
      this.clusterer.addMarkers(this.currentMarkers);
    },
    showInfo(markerInfo) {
      this.$store.dispatch('setShowInfo', { pan: false, item: markerInfo });
    },
    mobileMarkers(size) {
      let setSize = this.mobile ? (size === 1 ? 1 : size - 1) : size;
      return setSize === 0 ? 1 : setSize;
    },
  },
};
</script>
<style lang="scss">
.markerClass {
  color: #ccc;
}

.google-map {
  width: 100%;
  min-height: 100%;
}

.default-text {
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
}

.mobile-text {
  font-size: 12px !important;
}
.infoicon + div {
  display: none;
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 115px;
  text-align: left;
  width: 250px;
  background: #22272b;
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
}
.infoicon:hover + div {
  display: block;
}

.label-text {
  @import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap');
  font-family: 'Public Sans', sans-serif;
  font-size: 16px;
}

.mobile-label {
  font-size: 14px !important;
}
</style>
