<template>
  <v-icon v-if="value" color="success">check</v-icon>
  <v-icon v-else color="primary-red">close</v-icon>
</template>

<script>
export default {
  name: 'BooleanIcon',
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>

<style></style>
