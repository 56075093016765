<template>
  <Dialog v-bind="$attrs" displayCloseBtn icon="mdi-content-copy" @close="handleCloseDialog">
    <template #title>
      <div class="pt-6 px-10 d-flex align-center">
        <FeatureIcon class="mr-4" icon="mdi-upload-outline" />
        <div class="d-flex flex-column">
          <p class="mb-0 title">Upload school data</p>
          <p class="mb-0 body-1">Annual update of school data, to be requested from CESE team.</p>
        </div>
      </div>
    </template>
    <template #text>
      <div class="px-10 mt-6 dialogue--container">
        <Alert
          v-if="csvReport"
          type="error"
          text="Identified issues"
          subtext="Some issues have been identified in your file. The items have been highlighted and are ready for review via download."
          actionText="DOWNLOAD FILE"
          showAlert
          inPage
          backgroundColour
          :actionHandler="downloadCsvReport"
        />
        <div class="upload--container mt-8">
          <v-form ref="form" @submit.prevent="submitFile">
            <v-file-input
              v-model="selectedFile"
              label="Attachments"
              accept=".csv"
              type="file"
              outlined
              chips
              prependIcon=""
              prepend-inner-icon="mdi-paperclip"
              placeholder="Select your files"
              persistent-placeholder
              :error-messages="csvErrors"
              @click:clear="clearErrors"
              @input="clearErrors"
              @change="clearErrors"
            />
            <v-progress-linear
              v-if="isUploading"
              class="mb-6"
              indeterminate
              color="#407EC9"
              background-color="#EBEBEB"
              height="8"
              rounded
            />
            <div class="d-flex justify-end">
              <AdsButton buttonText="Cancel" :disabled="isUploading" secondary @click="handleCloseDialog" />
              <AdsButton
                buttonText="Upload"
                class="ml-4 default-text"
                icon="mdi-upload-outline"
                primary
                type="submit"
                :disabled="!selectedFile || isUploading"
              />
            </div>
          </v-form>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { AdsButton, Dialog, FeatureIcon, Alert } from '@nswdoe/doe-ui-core';
import { create_download_link } from '@/helpers/ui/createDownloadLink';

export default {
  name: 'UploadSchoolsDialog',
  components: { Dialog, AdsButton, FeatureIcon, Alert },
  data() {
    return {
      selectedFile: null,
      isUploading: false,
      csvErrors: [],
      csvReport: null,
    };
  },
  methods: {
    ...mapActions('moduleAdmin', ['getSchools', 'uploadSchoolsCsv']),
    handleCloseDialog() {
      this.selectedFile = null;
      this.csvErrors = [];
      this.csvReport = null;
      this.$emit('close');
    },
    async submitFile() {
      this.isUploading = true;
      const formData = new FormData();
      formData.append('file', this.selectedFile);

      try {
        const response = await this.uploadSchoolsCsv(formData);
        // Enable download if the response contains the imported data
        if (response.errorItems && typeof response.errorItems === 'string') {
          // If the data is base64 encoded, decode it, otherwise use the data as is
          const decodedData = response.isBase64Encoded ? atob(response.errorItems) : response.errorItems;

          // Create a Blob with the decoded data
          this.csvReport = new Blob([decodedData], { type: 'text/csv' });
        } else {
          this.$refs.form.reset();
          this.$emit('close');
          await this.getSchools();
        }
      } catch (err) {
        console.error(err);
        if (err) {
          this.csvErrors.push('Your file could not be uploaded. Please check the file and try again.');
        }
      } finally {
        this.isUploading = false;
      }
    },
    downloadCsvReport() {
      create_download_link(this.csvReport, 'imported.csv');
    },
    clearErrors() {
      this.csvErrors = [];
      this.csvReport = null;
    },
  },
  emits: ['close'],
};
</script>

<style lang="scss" scoped>
.dialogue--container {
  display: 'flex';
  gap: 0.5em;
  flex-direction: 'column';
}

.schema--container {
  display: grid !important;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 0.5em;
}

.upload--container {
  display: grid;
  grid-auto-columns: max-content;
  grid-template-columns: 1fr;
}

:deep(.theme--light.v-chip:not(.v-chip--active)) {
  background: $ads-blue-2;
}

:deep(.v-chip.v-size--default) {
  border-radius: 4px;
}

:deep(.theme--light.v-chip) {
  color: $ads-white;
}
</style>
