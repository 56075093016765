<template>
  <div class="d-flex flex-column">
    <v-row class="mt-3 mb-6" justify="space-between">
      <v-col>
        <div class="d-flex align-center chip-filters" role="group" aria-labelledby="schools-chip-group-label">
          <span id="schools-chip-group-label" class="mr-4 body-2 grey-02--text">Filter by:</span>
          <ChipFilter
            v-model="selectedTransferPoints"
            name="Transfer points"
            heading="Transfer points"
            :items="transferPointsFilterCheckboxes"
          />
          <ChipFilter
            v-model="selectedIncentiveSchool"
            name="Incentive school"
            heading="Incentive school"
            :items="filterRadioButtons"
            type="radio"
          />
          <ChipFilter
            v-model="selectedConnectedCommunitySchool"
            name="Connected community school"
            heading="Connected community school"
            :items="filterRadioButtons"
            type="radio"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-end">
        <AdsButton
          secondary
          class="mr-6"
          buttonText="Download"
          icon="mdi-download-outline"
          :disabled="filteredSchools?.length === 0 || !filteredSchools"
        >
          <v-icon class="mt-1 pr-1 material-icons-outlined"> download </v-icon>
          <download-csv :data="csvData"> Download </download-csv>
        </AdsButton>
        <AdsButton buttonText="Upload CSV" icon="mdi-upload-outline" @click="openUploadDialog" />
        <!-- <router-link :to="{ name: 'addSchool' }">
            <AdsButton buttonText="Add school" icon="mdi-plus-circle-outline" />
          </router-link> -->
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader
          v-if="isLoading"
          type="heading, table-row-divider, table-row@10, table-tfoot"
        />
        <AdsDataTable v-else :headers="tableHeaders" :items="filteredSchools">
          <template #[`item.isIncentive`]="{ item }">
            <BooleanIcon :value="item?.isIncentive" />
          </template>
          <template #[`item.isHot`]="{ item }">
            <BooleanIcon :value="item?.isHot" />
          </template>
          <template #[`item.isCC`]="{ item }">
            <BooleanIcon :value="item.principalNetwork?.toLowerCase().includes('connected communities team')" />
          </template>
          <template #[`item.isCold`]="{ item }">
            <BooleanIcon :value="item?.isCold" />
          </template>
          <template #[`item.isLateOpening`]="{ item }">
            <BooleanIcon :value="item?.isLateOpening" />
          </template>
          <template #[`item.isDemonstration`]="{ item }">
            <BooleanIcon :value="item?.isDemonstration" />
          </template>
          <template #[`item.isYancowinna`]="{ item }">
            <BooleanIcon :value="item?.isYancowinna" />
          </template>
          <template #[`item.totalBenefits`]="{ item }">
            up to <b>{{ `$${item.totalBenefits?.toLocaleString() ?? 0}` }}</b>
          </template>
          <template #item.actions="{ item }">
            <div class="d-flex">
              <v-icon color="primary-red" @click="openDeleteDialog(item.PK)"> mdi-delete-outline </v-icon>
              <v-icon class="ml-2" @click="editSchool(item.PK)"> mdi-chevron-right </v-icon>
            </div>
          </template>
        </AdsDataTable>
      </v-col>
    </v-row>
    <UpdateSchoolsDialog :openDialog="openUploadSchoolsDialog" @close="handleCloseUploadDialog" />
    <DeleteDialog :school="selectedSchool" :showDialog="showDeleteDialog" @close="handleCloseDeleteDialog" />
  </div>
</template>

<script>
import { AdsDataTable, AdsButton, ChipFilter } from '@nswdoe/doe-ui-core';
import BooleanIcon from '@/components/UI/BooleanIcon.vue';
import DeleteDialog from '@/components/UI/DeleteDialog.vue';
import UpdateSchoolsDialog from '@/views/Admin/Schools/UploadSchoolsDialog.vue';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'Schools',
  components: { AdsDataTable, AdsButton, BooleanIcon, ChipFilter, DeleteDialog, UpdateSchoolsDialog },
  data() {
    return {
      showDeleteDialog: false,
      openUploadSchoolsDialog: false,
      tableHeaders: [
        {
          text: 'School name',
          value: 'schoolFullName',
        },
        {
          text: 'School code',
          value: 'schoolCode',
        },
        {
          text: 'E-mail',
          value: 'email',
        },
        {
          text: 'Phone',
          value: 'phone',
        },
        {
          text: 'Level',
          value: 'schoolLevel',
        },
        {
          text: 'Student number',
          value: 'studentNumber',
        },
        {
          text: 'Address',
          value: 'address',
        },
        {
          text: 'Region',
          value: 'region',
        },
        {
          text: 'Transfer points',
          value: 'transferPoints',
        },
        {
          text: 'Incentive school',
          value: 'isIncentive',
        },
        {
          text: 'Socio-economic group',
          value: 'seGroup',
        },
        {
          text: 'Hot climatic allowance',
          value: 'isHot',
        },
        {
          text: 'Cold climatic allowance',
          value: 'isCold',
        },
        {
          text: 'Late opening allowance',
          value: 'isLateOpening',
        },
        {
          text: 'Demonstration lesson allowance',
          value: 'isDemonstration',
        },
        {
          text: 'Yancowinna allowance',
          value: 'isYancowinna',
        },
        {
          text: 'School population gender',
          value: 'schPopGender',
        },
        {
          text: 'Connected Community School',
          value: 'isCC',
        },
        {
          text: 'Total benefits',
          value: 'totalBenefits',
        },
        { text: '', value: 'actions', sortable: false },
      ],
      transferPointsFilterCheckboxes: [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 4, name: '4' },
        { id: 6, name: '6' },
        { id: 8, name: '8' },
      ],
      filterRadioButtons: [
        { id: 'yes', name: 'Yes' },
        { id: 'no', name: 'No' },
      ],
      selectedTransferPoints: [],
      selectedIncentiveSchool: null,
      selectedConnectedCommunitySchool: null,
    };
  },
  computed: {
    ...mapState(['isLoading']),
    ...mapState('moduleAdmin', ['schools', 'selectedSchool']),
    schoolsWithTotalBenefits() {
      return this.schools.map((school) => {
        return { ...school, totalBenefits: school.benefits?.total };
      });
    },
    filteredSchools() {
      let filteredSchools = this.schoolsWithTotalBenefits;

      // Filter by transfer points
      if (this.selectedTransferPoints.length > 0) {
        filteredSchools = filteredSchools.filter((school) =>
          this.selectedTransferPoints.includes(school.transferPoints)
        );
      }

      // Filter incentive schools
      if (this.selectedIncentiveSchool === 'no') {
        filteredSchools = filteredSchools.filter((school) => !school.isIncentive);
      } else if (this.selectedIncentiveSchool === 'yes') {
        filteredSchools = filteredSchools.filter((school) => school.isIncentive);
      }

      // Filter connected community schools
      if (this.selectedConnectedCommunitySchool === 'no') {
        filteredSchools = filteredSchools.filter(
          (school) => !school.principalNetwork.toLowerCase().includes('connected communities')
        );
      } else if (this.selectedConnectedCommunitySchool === 'yes') {
        filteredSchools = filteredSchools.filter((school) =>
          school.principalNetwork.toLowerCase().includes('connected communities')
        );
      }

      return filteredSchools;
    },
    csvData() {
      return this.filteredSchools.map((school) => {
        return {
          schoolCode: school.schoolCode,
          schoolFullName: school.schoolFullName,
          address: school.address,
          studentNumber: school.studentNumber,
          schoolLevel: school.schoolLevel,
          region: school.region,
          principalNetwork: school.principalNetwork,
          schSpecialtyType: school.schSpecialtyType,
          isDemonstration: school.isDemonstration ? 'Y' : 'N',
          phone: school.phone,
          email: school.email,
          isLateOpening: school.isLateOpening ? 'Y' : 'N',
          isHot: school.isHot ? 'Y' : 'N',
          isYancowinna: school.isYancowinna ? 'Y' : 'N',
          isCold: school.isCold ? 'Y' : 'N',
          isIncentive: school.isIncentive ? 'Y' : 'N',
          seGroup: school.seGroup,
          transferPoints: school.transferPoints,
          schPopGender: school.schPopGender,
          lat: school.lat,
          lng: school.lng,
        };
      });
    },
  },
  methods: {
    ...mapMutations('moduleAdmin', ['SET_SELECTED_SCHOOL', 'RESET_SELECTED_SCHOOL']),
    ...mapActions('moduleAdmin', ['getSchools']),
    openUploadDialog() {
      this.openUploadSchoolsDialog = true;
    },
    handleCloseUploadDialog() {
      this.openUploadSchoolsDialog = false;
    },
    selectSchool(PK) {
      const school = this.schools.find((school) => school.PK === PK);
      if (school) {
        this.SET_SELECTED_SCHOOL(school);
      }
    },
    openDeleteDialog(PK) {
      this.selectSchool(PK);
      this.showDeleteDialog = true;
    },
    handleCloseDeleteDialog() {
      this.showDeleteDialog = false;
      this.RESET_SELECTED_SCHOOL();
    },
    editSchool(PK) {
      this.selectSchool(PK);
      this.$router.push({ name: 'editSchool', params: { schoolCode: this.selectedSchool.schoolCode } });
    },
  },
  async created() {
    await this.getSchools({ spouse: 'DEP', children: '8' });
  },
};
</script>

<style scoped lang="scss">
.chip-filters {
  gap: 8px;
}
</style>
