<template>
  <div>
    <v-snackbar v-model="showSnackbar" color="white" bottom right multi-line app>
      <div class="d-flex flex-row align-center">
        <v-icon color="success">mdi-check-circle</v-icon>
        <p class="my-0 ml-3 font-weight-bold grey-01--text">{{ snackbar.message }}</p>
      </div>
    </v-snackbar>
    <router-view aria-live="polite" />
    <Dialog
      :openDialog="error.isError"
      title="Something went wrong..."
      icon="mdi-alert-circle-outline"
      :message="error.message"
      displayCloseBtn
      @close="RESET_ERROR"
    />
  </div>
</template>

<script>
import { Dialog } from '@nswdoe/doe-ui-core';

import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Admin',
  components: { Dialog },
  computed: {
    ...mapState('moduleAdmin', ['snackbar', 'error']),
    showSnackbar: {
      get() {
        return this.snackbar.visible;
      },
      set() {
        this.RESET_SNACKBAR();
      },
    },
  },
  methods: {
    ...mapMutations('moduleAdmin', ['RESET_SNACKBAR', 'RESET_ERROR']),
  },
  created() {
    if (!this.$OAuth.data?.profile?.groups?.includes('DDD-Benefits-Calc-Admin')) {
      this.$router.push({ name: 'home' });
    }
  },
};
</script>

<style lang="scss" scoped></style>
