<template>
  <Dialog v-bind="$attrs" displayCloseBtn icon="mdi-upload-outline" @close="handleCloseDialog">
    <template #title>
      <div class="pt-6 px-10 d-flex align-top">
        <FeatureIcon class="mr-4 flex-shrink-0" icon="mdi-upload-outline" />
        <div class="d-flex flex-column">
          <p class="mb-0 title">{{ dialogHeaders.title }}</p>
          <p class="mb-0 body-1">{{ dialogHeaders.description }}</p>
        </div>
      </div>
    </template>
    <template #text>
      <div v-if="step === 1" class="px-10 mt-6 dialogue--container">
        <v-select
          v-model="selectedSchema"
          label="File type"
          hint="Select file type (schema)"
          :items="benefitSchemaOptions"
          item-text="title"
          item-value="value"
          persistent-placeholder
          persistent-hint
          outlined
          return-object
        >
          <template #item="{ item }">
            <div>
              <div class="body-1 menu-item__title">{{ item.title }}</div>
              <div class="body-2 menu-item__description">{{ item.description }}</div>
            </div>
          </template>
        </v-select>
        <div class="d-flex justify-end">
          <AdsButton buttonText="Cancel" secondary @click="handleCloseDialog" />
          <AdsButton
            buttonText="Next"
            class="ml-4 default-text"
            icon="mdi-arrow-right"
            primary
            type="submit"
            :disabled="!selectedSchema"
            @click="proceedToSubmission"
          />
        </div>
      </div>
      <div v-else class="px-10 mt-6 dialogue--container">
        <Alert
          v-if="csvReport"
          ref="alert"
          type="warning"
          text="Notice: Data Upload Issues Detected"
          subtext="Some of the data you attempted to upload was not successfully processed due to errors. To help you identify and correct these issues, you can download a detailed report by clicking the link below."
          actionText="DOWNLOAD FILE"
          showAlert
          inPage
          backgroundColour
          :actionHandler="downloadCsvReport"
        />
        <div class="upload--container mt-8">
          <v-form ref="form" @submit.prevent="submitFile">
            <v-file-input
              v-model="selectedFile"
              label="Attachments"
              accept=".csv"
              type="file"
              outlined
              chips
              prependIcon=""
              prepend-inner-icon="mdi-paperclip"
              placeholder="Select your files"
              persistent-placeholder
              show-size
              :error-messages="csvErrors"
              @click:clear="clearErrors"
              @input="clearErrors"
              @change="clearErrors"
            />
            <v-progress-linear
              v-if="isUploading"
              class="mb-6"
              indeterminate
              color="#407EC9"
              background-color="#EBEBEB"
              height="8"
              rounded
            />
            <div class="d-flex justify-end">
              <AdsButton buttonText="Cancel" secondary :disabled="isUploading" @click="returnToSchemaSelection" />
              <AdsButton
                buttonText="Upload"
                class="ml-4 default-text"
                icon="mdi-upload-outline"
                primary
                type="submit"
                :disabled="!selectedFile || isUploading"
              />
            </div>
          </v-form>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { AdsButton, Dialog, Alert, FeatureIcon } from '@nswdoe/doe-ui-core';
import { create_download_link } from '@/helpers/ui/createDownloadLink';

export default {
  name: 'UploadBenefitsDialog',
  components: { Dialog, AdsButton, Alert, FeatureIcon },
  props: { selectedBenefitSchema: { type: String, default: 'basic' } },
  data() {
    return {
      step: 1,
      selectedSchema: null,
      selectedFile: null,
      isUploading: false,
      csvErrors: [],
      csvReport: null,
      benefitSchemaOptions: [
        {
          value: 'basic',
          title: 'Benefit Description',
          description: 'Ad hoc update for all benefits and allowances descriptions shown in the Benefits Calculator.',
        },
        {
          value: 'misc',
          title: 'Misc Benefits',
          description:
            'Ad hoc update for Vacation travel expense, TOC, Extended Summer Vacation and Medical and Dental reimbursement. School Workforce will provide the file in consultation with Industrial Relations',
        },
        {
          value: 'relocationSubsidy',
          title: 'Relocation Subsidy',
          description:
            'Ad hoc update of schools eligible for the Relocation Subsidy. Data to be updated by School Workforce when there is a change to policy.',
        },
        {
          value: 'rrw',
          title: 'RRW Incentives',
          description:
            'Ad hoc update for major financial incentives. To be provided by the Rural and Remote team, School Workforce.',
        },
        {
          value: 'T510J',
          title: 'Payroll - T510J',
          description: 'Annual update of award allowance data, export from payroll.',
        },
        {
          value: 'T510K',
          title: 'Payroll - T510K',
          description: 'Annual update of award allowance data, export from payroll.',
        },
        {
          value: 'zpyc',
          title: 'Payroll - ZPYC_ALLOWANCE',
          description: 'Annual update of award allowance data, export from payroll.',
        },
        {
          value: 'iSeries',
          title: 'iSeries - TEPS School Benefit',
          description: 'Annual update of Teacher Employment Priority Scheme data, export from iSeries.',
        },
      ],
    };
  },
  computed: {
    dialogHeaders() {
      if (this.step === 2 && this.selectedSchema) {
        return { title: this.selectedSchema.title, description: this.selectedSchema.description };
      } else {
        return { title: 'Upload benefits data file', description: 'Upload benefits from a csv file.' };
      }
    },
  },
  methods: {
    ...mapActions('moduleAdmin', ['getBenefits', 'uploadBenefitsCsv']),
    proceedToSubmission() {
      this.step = 2;
    },
    returnToSchemaSelection() {
      this.step = 1;
      this.selectedFile = null;
      this.csvReport = null;
    },
    handleCloseDialog() {
      this.selectedSchema = null;
      this.csvErrors = [];
      this.csvReport = null;
      this.$emit('close');
      this.returnToSchemaSelection();
    },
    async submitFile() {
      this.isUploading = true;
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      let schemaType;
      switch (this.selectedSchema.value) {
        case 'rrw':
        case 'relocationSubsidy':
        case 'iSeries':
          schemaType = 'school_code';
          break;
        default:
          break;
      }

      try {
        const response = await this.uploadBenefitsCsv({
          csvSchema: schemaType ?? this.selectedSchema.value,
          csvFile: formData,
        });

        // Enable download if the response contains the imported data
        if (response.errorItems && typeof response.errorItems === 'string') {
          // If the data is base64 encoded, decode it, otherwise use the data as is
          const decodedData = response.isBase64Encoded ? atob(response.errorItems) : response.errorItems;

          // Create a Blob with the decoded data
          this.csvReport = new Blob([decodedData], { type: 'text/csv' });
        } else {
          this.handleCloseDialog();
          await this.getBenefits();
        }
      } catch (err) {
        console.error(err);
        if (err) {
          this.csvErrors.push('Your file could not be uploaded. Please check the file and try again.');
        }
      } finally {
        this.isUploading = false;
      }
    },
    downloadCsvReport() {
      create_download_link(this.csvReport, 'imported.csv');
    },
    clearErrors() {
      this.csvErrors = [];
      this.csvReport = null;
    },
  },
  watch: {
    csvReport(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          const alertComponent = this.$refs.alert;
          const alertContent = alertComponent.$el.querySelector('.v-alert__content');
          if (alertContent) {
            const additionalDiv = document.createElement('div');
            additionalDiv.classList.add('pt-3');
            additionalDiv.textContent = 'Note: It is normal for file headers not to be uploaded.';
            alertContent.appendChild(additionalDiv);
          }
        });
      }
    },
  },
  emits: ['close'],
};
</script>

<style lang="scss" scoped>
.dialogue--container {
  display: 'flex';
  gap: 0.5em;
  flex-direction: 'column';
}

.upload--container {
  display: grid;
  grid-auto-columns: max-content;
  grid-template-columns: 1fr;
}

:deep(.theme--light.v-chip:not(.v-chip--active)) {
  background: $ads-blue-2;
}

:deep(.v-chip.v-size--default) {
  border-radius: 4px;
}

:deep(.theme--light.v-chip) {
  color: $ads-white;
}

.menu-item {
  &__title {
    color: $ads-grey-01;
  }
  &__description {
    color: $ads-grey-02;
  }
}

:deep.v-list.v-select-list {
  max-width: 595px !important;
}

// styles from AdsSelect, using v-select, as ads doesn't provide slots for the component
::v-deep.v-list {
  .v-list-item {
    padding: 16px;
    transition: background-color 0.3s ease-out;
  }

  .v-list-item.v-list-item--highlighted {
    border-collapse: separate; /* for ie 9-11 */
    box-shadow: 0px 0px 0px 3px $ads-blue-3-dark inset;

    &:before {
      opacity: 0;
    }
  }

  &.theme--light {
    .v-list-item.v-list-item--active {
      background-color: $ads-navy;
      color: $ads-white !important;

      .menu-item__title,
      .menu-item__description {
        color: $ads-white;
      }
    }
  }

  &.theme--dark {
    .v-list-item.v-list-item--active {
      background-color: $ads-navy-dark;
      color: $ads-01dp !important;

      &:before {
        opacity: 0;
      }
    }
  }
}
</style>
