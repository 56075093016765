<template>
  <div>
    <Banner defaultBackground2 heading="Administration" icon="mdi-cog-outline" />
    <v-container class="px-md-10">
      <TabNavigation :items="items" :value="activeTab">
        <template #contentschools>
          <Schools />
        </template>
        <template #contentbenefits>
          <Benefits />
        </template>
      </TabNavigation>
    </v-container>
  </div>
</template>

<script>
import { Banner, TabNavigation } from '@nswdoe/doe-ui-core';
import Schools from '@/views/Admin/Schools';
import Benefits from '@/views/Admin/Benefits';

export default {
  name: 'Manage',
  components: {
    Banner,
    TabNavigation,
    Schools,
    Benefits,
  },
  data() {
    return {
      activeTab: 0,
      items: [
        {
          id: 'schools',
          tab: 'Schools',
        },
        {
          id: 'benefits',
          tab: 'Benefits',
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'variants') {
        vm.$data.activeTab = 1;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
:deep(.v-slide-group__wrapper) {
  min-width: 208px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $ads-grey-03;
  }
}

:deep(.v-tab) {
  min-width: 208px;
}

:deep(.banner .appTitle) {
  font-size: 34px;
}
</style>
