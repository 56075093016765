<template>
  <Dialog
    :openDialog="showDialog"
    :message="`Are you
    sure you want to delete ${school?.schoolFullName}?`"
    displayCloseBtn
    maxWidth="500px"
    @close="handleCloseDialog"
  >
    <template #title>
      <v-card-title class="d-flex justify-space-between">
        <div class="delete-dialog__title title grey-01--text">
          <v-avatar size="40">
            <v-icon>mdi-delete-outline</v-icon>
          </v-avatar>
          Delete school
        </div>
      </v-card-title>
    </template>
    <template #text>
      <div class="my-10 px-16 text-center">Are you sure you want to delete {{ school?.schoolFullName }}?</div>
    </template>
    <template #actions>
      <v-card-actions class="pb-6 px-6 justify-end">
        <AdsButton class="mr-3" secondary buttonText="Cancel" @click="handleCloseDialog" />
        <AdsButton
          class="white--text"
          primary
          color="primary-red"
          buttonText="Delete"
          icon="mdi-delete-outline"
          @click="deleteSchool"
        />
      </v-card-actions>
    </template>
  </Dialog>
</template>

<script>
import { AdsButton, Dialog } from '@nswdoe/doe-ui-core';
import { mapMutations, mapActions } from 'vuex';
export default {
  components: { AdsButton, Dialog },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    school: {
      type: Object,
      default: null,
    },
  },
  methods: {
    ...mapMutations('moduleAdmin', ['RESET_SELECTED_SCHOOL']),
    ...mapActions('moduleAdmin', ['getSchools', 'createUpdateDeleteSchool']),
    handleCloseDialog() {
      this.$emit('close');
    },
    async deleteSchool() {
      await this.createUpdateDeleteSchool({ formData: { ...this.school, active: false }, type: 'delete' });
      this.$emit('close');
      if (this.$route.name !== 'manage') {
        this.$router.push({ name: 'manage' });
      } else {
        this.RESET_SELECTED_SCHOOL();
        await this.getSchools({ spouse: 'DEP', children: '8' });
      }
    },
  },
  emits: ['close'],
};
</script>

<style scoped lang="scss">
.v-card__title {
  padding: 24px 10px 0 24px;
  margin-bottom: 18px;
  color: $ads-primary-blue !important;

  .v-avatar {
    background-color: rgba(215, 21, 58, 0.12) !important;
    margin-right: 6px;

    i {
      color: $ads-primary-red;
    }
  }
}
</style>
