<template>
  <v-container class="card-container">
    <ErrorPage title="The page you are looking for does not exist" is404Page />
  </v-container>
</template>

<script>
import { ErrorPage } from '@nswdoe/doe-ui-core';
export default {
  name: 'NotFound',
  components: { ErrorPage },
};
</script>

<style scoped lang="scss">
.card-container {
  margin: 0 auto;
  position: absolute;
  top: calc(50% - 64px);
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
