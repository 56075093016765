<template>
  <v-app class="d-flex flex-column" :class="mobile ? 'mobile' : ''">
    <AppBar appName="my-nsw-gov" color="primary" title="Benefits Calculator" :showNavIcon="isAdmin">
      <template #appIcon>
        <AppIcon :transparent="true" appName="my-nsw-gov" class="mt-2 ml-6" size="50px" />
      </template>
      <template #profile>
        <Profile
          v-if="userProfile"
          :givenName="userNames.givenName"
          :surname="userNames.surname"
          :changePassword="false"
          :portalSettings="false"
          :updateProfile="false"
          :updateSecretQuestion="false"
          :logoutHandler="logoutHandler"
        />
        <AdsButton
          v-else
          :class="$vuetify.breakpoint.xsOnly ? 'mr-n5' : ''"
          buttonText="Admin log in"
          color="white"
          icon="mdi-account-circle-outline"
          tertiary
          @click="handleLoginClick"
        />
      </template>
    </AppBar>
    <div class="contents fill-height">
      <v-main class="fill-height">
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-main>
    </div>
    <NavigationDrawer v-if="isAdmin" :items="menuItems" :showProfileMobile="true" />
    <!-- App-wide component -->
    <BlockUI v-if="$store.state.isLoading && !isAdminRoute" />
  </v-app>
</template>

<script>
import { AdsButton, AppBar, AppIcon, BlockUI, Profile, NavigationDrawer } from '@nswdoe/doe-ui-core';
import { SIZES } from '@/constants';
import { jwtDecode } from 'jwt-decode';
import { checkIsAdmin } from '@/helpers/utils';

export default {
  name: 'App',
  title: 'Benefits Calculator',
  components: {
    AdsButton,
    AppBar,
    AppIcon,
    BlockUI,
    Profile,
    NavigationDrawer,
  },
  data() {
    return {
      SIZES,
      scrollerHeight: 0,
      menuItems: [
        {
          id: 'benefitsCalculator',
          title: 'Benefits Calculator',
          icon: 'mdi-calculator',
          route: '/',
          exact: true,
        },
        {
          id: 'administration',
          title: 'Administration',
          icon: 'mdi-cog-outline',
          route: '/admin',
        },
      ],
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    userProfile() {
      if (!this.$OAuth?.data?.loaded) {
        const decodedToken = localStorage.getItem('access_token')
          ? jwtDecode(localStorage.getItem('access_token'))
          : undefined;
        if (decodedToken?.exp && decodedToken?.exp > Date.now() / 1000) {
          return decodedToken;
        } else return null;
      } else if (this.$OAuth?.data?.loaded) {
        return this.$OAuth?.data?.profile;
      } else return null;
    },
    userNames() {
      if (this.userProfile) {
        const { name } = this.userProfile;
        return this.splitFirstWord(name);
      } else
        return {
          givenName: '',
          surname: '',
        };
    },
    isAdmin() {
      if (this.userProfile) {
        return checkIsAdmin(this.userProfile);
      } else {
        return false;
      }
    },
    isAdminRoute() {
      return this.$route.name === 'manage' || this.$route.name === 'variants';
    },
  },
  methods: {
    splitFirstWord(inputString) {
      // Split the input string by the first space
      const firstSpaceIndex = inputString.indexOf(' ');
      let firstWord, restOfString;

      if (firstSpaceIndex !== -1) {
        // Extract the first word and the rest of the string
        firstWord = inputString.slice(0, firstSpaceIndex).trim();
        restOfString = inputString.slice(firstSpaceIndex + 1).trim();
      } else {
        // If there's no space, the whole input is considered as the first word
        firstWord = inputString.trim();
        restOfString = '';
      }

      // Return an object with the first word and the rest of the string
      return {
        givenName: firstWord,
        surname: restOfString,
      };
    },
    logoutHandler() {
      this.$OAuth.logout();
    },
    handleLoginClick() {
      this.$router.push('/admin/manage');
    },
  },
};
</script>

<style lang="scss">
// use pre-defined ADS scss classes for the whole project, note here the styles are non-scoped in App.vue
@import './scss/ads';
@import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap');
// example of overriding classes in `ads.scss`
.theme--light {
  // override the pre-defined scss classes for <a> in ads.scss as per the design for this project
  /*  a:not(.v-btn, .v-tab) {
      color: $ads-blue-1;

      &:visited {
        color: $ads-blue-1;
      }
    }*/
}

.mobile .col {
  padding: 4px !important;
}

.mobile .row {
  margin: 0 !important;
}

.banner {
  //   height: auto;
  //   min-height: auto;

  &.row {
    margin: 0 !important;
  }

  //   .container {
  //     max-width: 100%;
  //   }
}

.mobile .contents {
  height: calc(100vh - 160px);
}

// .mobile .banner {
//   min-height: 160px;
//   height: 160px;
// }
.appSubtitle-mobile {
  font-size: 16px;
  padding: 0 14px;
  margin-bottom: 0;
}

.appTitle-mobile {
  font-size: 25px;
  font-weight: 600;
  display: inline-block;
  padding: 0 12px;
  margin: 0;
  vertical-align: top;
}

.container {
  padding: 0;

  .container {
    padding: 0;
  }
}

.mobile {
  .container {
    padding: 12px 4px;
  }
}

.default-text {
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
}

.label-text {
  font-size: 16px !important;
}

.title-text {
  font-size: 20px !important;
}

.font-bold {
  font-weight: bold !important;
}

.mobile-text {
  font-size: 12px !important;
}

.mobile-label {
  font-size: 14px !important;
}

.theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent;
}

@media (max-width: 600px) {
  .v-toolbar__content {
    justify-content: space-between;
  }
  #appBarSpacer {
    display: none;
  }
  .title--width.expandTitle {
    width: auto;
  }
  .v-toolbar__title .essential {
    margin-left: 0 !important;
  }
}
</style>
