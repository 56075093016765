/**
 * Method to create a download link for a Blob object.
 * Creates a new link element, appends it to the document, triggers the download, and removes the link.
 * @param blob - The Blob object to download
 * @param filename - The filename to use for the download, defaults to 'download'
 * @example
 * const blob = new Blob([decodedData], { type: 'text/csv' });
 * create_download_link(blob, 'imported.csv');
 */
export const create_download_link = (blob: Blob, filename?: string) => {
  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = filename || 'download';
  downloadLink.style.display = 'none';

  // Append the link to the document and trigger the download
  document.body.appendChild(downloadLink);
  downloadLink.click();

  // Clean up by removing the link and revoking the URL
  document.body.removeChild(downloadLink);
  window.URL.revokeObjectURL(url);
};

