import { getEntity, createUpdateDeleteEntity, uploadEntityCsv } from '@/api/utils';

const getSchools = (searchParams?) => {
  if (searchParams) {
    const urlSearchParams = new URLSearchParams(searchParams);
    return getEntity(`schools?${urlSearchParams}`);
  } else return getEntity('schools');
};

const getSchoolsWithCalculatedBenefits = (searchParams) => {
  const urlSearchParams = new URLSearchParams(searchParams);
  return getEntity(`calculate?${urlSearchParams}`);
};

const createUpdateDeleteSchool = (data) => {
  return createUpdateDeleteEntity('schools', data);
};

const uploadSchoolsCsv = async (data) => {
  return uploadEntityCsv('/schools/import', 'basic', data);
};

export default {
  getSchools,
  getSchoolsWithCalculatedBenefits,
  createUpdateDeleteSchool,
  uploadSchoolsCsv,
};
