import router from '@/router';
import { OAuth } from '@nswdoe/doe-ui-core';
import { Buffer } from 'buffer';
import { configure } from '@/api/http';

globalThis.Buffer = Buffer;

const authOptions = {
  router: router,
  pkce: true, // (recommended to be true for OpenAM SSO, and required to be true for Azure SSO)
  authoriseUri: process.env.VUE_APP_BC_AUTH_URL,
  tokenUri: process.env.VUE_APP_BC_TOKEN_URL, // (this field is only required for Azure SSO)
  logoutUri: process.env.VUE_APP_BC_LOGOUT_URL,
  forceProdMode: true,
  secureApp: false,
  params: {
    client_id: process.env.VUE_APP_BC_AUTH_CLIENT_ID,
    redirect_uri: encodeURIComponent(`${window.location.origin}/login/callback`),
    scope: encodeURIComponent(`${process.env.VUE_APP_BC_AUTH_CLIENT_ID}/.default openid profile email`),
    // response_type: 'id_token',
  },
  afterLogin: (accessToken?: string /* idToken?: string */) => {
    configure(accessToken);
  },
};

export const oauth = (vue: Vue) => {
  OAuth.install(vue, authOptions);
};
