<template>
  <div>
    <Banner
      :iconSize="SIZES.BANNER_ICON.DESKTOP"
      contentClass="pl-3"
      defaultBackground1
      icon="mdi-calculator"
      subtitle="Learn about the range of benefits and allowances for teachers and executives in NSW public schools."
    >
      <template #title>
        <h2 class="banner__title">Benefits Calculator</h2>
      </template>
    </Banner>
    <v-card v-if="!isDontShowAgain">
      <Dialog
        :displayCloseBtn="true"
        :openDialog="showPopup"
        icon="info_outline"
        title="Disclaimer"
        width="550px"
        @close="handleDialogReturn"
      >
        <template #text>
          <div class="pl-6 pr-6 d-flex flex-column">
            <div>
              The benefits and allowances calculator requires entry of some personal information. This information is
              not retained or tracked.
            </div>
            <div class="pt-5">
              <span class="font-weight-bold">Please note</span>
              that the results provided by the calculator are indicative only.
            </div>
            <div class="pt-5 d-flex justify-end align-center">
              <span>
                <v-checkbox v-model="setDontShowAgain" class="pr-5" label="Don't show again" />
              </span>
              <span>
                <AdsButton primary @click="handleDialogReturn"> Close </AdsButton>
              </span>
            </div>
          </div>
        </template>
      </Dialog>
    </v-card>
    <Dialog
      v-if="showModal"
      :displayCloseBtn="true"
      :fullscreen="mobile"
      :openDialog="showModal"
      :returnHandler="handleDialogReturn"
      maxWidth="1150px"
      @close="setShowModal(false)"
    >
      <template #title>
        <v-card-title class="mb-5 pt-6 pr-3 pl-6 d-flex justify-space-between">
          <h2 class="title primary-blue--text">School information</h2>
        </v-card-title>
      </template>
      <template #text>
        <div class="mt-3 pa-3">
          <InfoWindow />
        </div>
      </template>
    </Dialog>
    <div v-if="!mobile" class="d-flex flex-wrap">
      <div>
        <aside :style="{ width: expanded ? '850px' : '475px' }" style="min-width: 475px">
          <SearchResults ref="searchResults" :contentWidth="expanded ? '850px' : '475px'" :results="results.schools" />
        </aside>
      </div>
      <div class="grow">
        <v-btn v-if="isUserSearch" class="skip-map" @click="handleSkipMap"> Skip past map </v-btn>
        <div v-if="isUserSearch" class="expandLeft ml-8 mt-6">
          <v-btn icon style="width: 48px; height: 48px" @click="expandResults">
            <v-icon v-if="!expanded" color="primary"> chevron_right </v-icon>
            <v-icon v-if="expanded" color="primary"> chevron_left </v-icon>
          </v-btn>
        </div>
        <MapView />
      </div>
    </div>
    <div v-else-if="mobile" style="width: 100vw">
      <div :style="`${mobile ? 'width: 100vw' : ''}`">
        <SearchResults :results="results.schools" :tabs="mobile">
          <template #mobile>
            <MapView :mapHeight="getMapHeight" />
          </template>
        </SearchResults>
      </div>
    </div>
  </div>
</template>

<script>
import { AdsButton, Banner, Dialog } from '@nswdoe/doe-ui-core';

import SearchResults from '@/components/SearchResults';
import { mapActions, mapGetters } from 'vuex';
import MapView from '@/components/googleMapLoader/MapInfo';
import InfoWindow from '@/components/infoWindow/InfoWindow';
import { SIZES } from '@/constants';

export default {
  name: 'App',
  title: 'Benefits Calculator',
  components: {
    Banner,
    SearchResults,
    MapView,
    Dialog,
    AdsButton,
    InfoWindow,
  },
  data() {
    return {
      expanded: false,
      setDontShowAgain: false,
      modalInfo: null,
      scrollerHeight: 0,
      showPopup: false,
      SIZES,
    };
  },
  computed: {
    ...mapGetters({
      isUserSearch: 'isUserSearch',
      results: 'moduleResults/results',
      isOpenDialog: 'isOpenDialog',
      isDontShowAgain: 'isDontShowAgain',
      showMarkerClicked: 'showInfo',
      formValues: 'formValues',
      showModal: 'showModal',
    }),
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    getMapHeight() {
      return this.scrollerHeight;
    },
  },
  created() {
    this.$nextTick(() => {
      this.showPopup = this.isOpenDialog;
    });
  },
  watch: {
    async showMarkerClicked(markerObj) {
      await this.$store.dispatch('moduleResults/fetchSelectedResult', {
        spouse: this.formValues.partnerType ?? 'NONE',
        children: this.formValues.dependents,
        schoolCode: markerObj?.item?.schoolCode,
      });
    },
  },
  methods: {
    ...mapActions(['setShowModal']),
    async handleDialogReturn() {
      // result = action.name e.g. "Ok" or "Cancel"
      this.showPopup = false;
      await this.$store.dispatch('setDontShowAgain', this.setDontShowAgain);
      await this.$store.dispatch('setOpenDialog', false);
    },
    checkForBanner() {
      return this.mobile && this.isUserSearch;
    },
    setScrollHeight(val) {
      this.scrollerHeight = val;
    },
    expandResults() {
      this.expanded = !this.expanded;
    },
    handleSkipMap() {
      this.$refs.searchResults.focusSearchField();
    },
  },
};
</script>

<style lang="scss">
// use pre-defined ADS scss classes for the whole project, note here the styles are non-scoped in App.vue
@import '../../scss/ads';
@import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap');
// example of overriding classes in `ads.scss`
.theme--light {
  // override the pre-defined scss classes for <a> in ads.scss as per the design for this project
  /*  a:not(.v-btn, .v-tab) {
        color: $ads-blue-1;
  
        &:visited {
          color: $ads-blue-1;
        }
      }*/
}

.skip-map {
  background: #fff;
  height: 30px;
  left: 50%;
  padding: 8px;
  position: absolute;
  transition: transform 0.3s;
  z-index: -1;

  &:focus {
    z-index: 5;
  }
}

.banner .container {
  max-width: 100%;
}

.appSubtitle-mobile {
  font-size: 16px;
  padding: 0 14px;
  margin-bottom: 0;
}

.appTitle-mobile {
  font-size: 25px;
  font-weight: 600;
  display: inline-block;
  padding: 0 12px;
  margin: 0;
  vertical-align: top;
}

.expandLeft {
  z-index: 1;
  position: absolute;
  width: 48px;
  height: 48px;
  padding: 0;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  /* 03 dp */
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 3px 0px rgba(0, 0, 0, 0.12),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14);
}

.container {
  padding: 0;

  .container {
    padding: 0;
  }
}

.banner__title {
  font-size: 40px;
  font-weight: 600;
  display: inline-block;
  padding: 0 12px;
  vertical-align: bottom;
}

.mobile {
  .container {
    padding: 12px 4px;
  }
}

.default-text {
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
}

.label-text {
  font-size: 16px !important;
}

.title-text {
  font-size: 20px !important;
}

.font-bold {
  font-weight: bold !important;
}

.mobile-text {
  font-size: 12px !important;
}

.mobile-label {
  font-size: 14px !important;
}
</style>
