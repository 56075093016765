import { getEntity, createUpdateDeleteEntity, uploadEntityCsv } from '@/api/utils';

const getBenefits = () => {
  return getEntity('benefits');
};

const createUpdateDeleteBenefit = (data) => {
  return createUpdateDeleteEntity('benefits', data);
};

const uploadBenefitsCsv = async (csvSchema, data) => {
  return uploadEntityCsv('/benefits/import', csvSchema, data);
};

export default {
  getBenefits,
  uploadBenefitsCsv,
  createUpdateDeleteBenefit,
};
