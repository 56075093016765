import { BenefitDetails, SchoolBenefits } from '@/types/benefit';
import { TokenPayload } from '@/types/Auth';

export const checkIsAdmin = (userProfile: TokenPayload) => {
  const { VUE_APP_BC_VALID_GROUPS, VUE_APP_BC_AUTH_TENANT_ID } = process.env;
  return (
    userProfile?.groups?.includes(VUE_APP_BC_VALID_GROUPS as string) && userProfile?.tid === VUE_APP_BC_AUTH_TENANT_ID
  );
};

export const addNSWBeforePostCode = (address) => {
  // Expression to match optional spaces followed by a four-digit number at the end of the string
  const postCodeRegex = /\s*(\d{4})$/;
  // Check if the regex matches in the address string
  if (postCodeRegex.test(address)) {
    // Use replace method to insert ", NSW" before the matched four-digit number
    return address.replace(postCodeRegex, ', NSW $1');
  } else {
    return address;
  }
};

export const getBenefitChipFilterOptions = (targetBenefitCodes, benefits) => {
  const uniqueNames = new Set();
  const targetBenefits = targetBenefitCodes.map((code) => {
    const benefit = benefits.find((benefit) => benefit.benefitCode === code);
    if (benefit) {
      if (uniqueNames.has(benefit.name)) {
        return;
      } else {
        uniqueNames.add(benefit.name);
        return {
          id: benefit.name,
          name: benefit.name,
        };
      }
    }
  });
  return targetBenefits.filter((benefit) => benefit !== undefined);
};

export const formatValue = (value: string) => {
  if (value) {
    return isNaN(+value) ? `${value}` : (+value).toLocaleString();
  } else {
    return '';
  }
};

export const findCategoryBenefits = (
  benefitDetails: BenefitDetails[],
  selectedSchoolBenefits: SchoolBenefits,
  filterValue?: boolean,
  category?: string
) => {
  const mappedBenefits = benefitDetails
    ?.filter((item) => !category || item.benefitCategory === category)
    ?.map((benefit) => ({
      ...benefit,
      value: selectedSchoolBenefits?.benefitItems?.find((item) => item.benefitCode === benefit.benefitCode)?.value,
    }));
  const uniqueBenefitDetails: Set<string> = new Set();
  const resultBenefits: BenefitDetails[] = [];
  for (const mappedBenefit of mappedBenefits) {
    if (
      uniqueBenefitDetails.has(
        JSON.stringify({
          name: mappedBenefit.name,
          description: mappedBenefit.description,
          benefitCategory: mappedBenefit.benefitCategory,
          valueDescription: mappedBenefit.valueDescription,
          valueDenomination: mappedBenefit.valueDenomination,
        })
      )
    ) {
      const foundBenefit = resultBenefits.find(
        (found) =>
          found.name === mappedBenefit.name &&
          found.description === mappedBenefit.description &&
          found.benefitCategory === mappedBenefit.benefitCategory &&
          found.valueDescription === mappedBenefit.valueDescription &&
          found.valueDenomination === mappedBenefit.valueDenomination
      );
      if (foundBenefit && foundBenefit.value === undefined && mappedBenefit.value !== undefined) {
        foundBenefit.value = mappedBenefit.value;
      }
    } else {
      resultBenefits.push(mappedBenefit);
      uniqueBenefitDetails.add(
        JSON.stringify({
          name: mappedBenefit.name,
          description: mappedBenefit.description,
          benefitCategory: mappedBenefit.benefitCategory,
          valueDescription: mappedBenefit.valueDescription,
          valueDenomination: mappedBenefit.valueDenomination,
        })
      );
    }
  }

  const filteredResult: BenefitDetails[] = resultBenefits
    .filter((item) => filterValue || item.value !== undefined)
    ?.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
        return 1;
      }
      return 0;
    });

  return filteredResult;
};
