<template>
  <v-row v-bind="$attrs">
    <v-col v-for="(field, index) in schoolDataFields" :key="index" sm="6">
      <div class="subtitle-1">{{ field.title }}</div>
      <div>{{ field.value }}</div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'SchoolReadOnly',
  computed: {
    ...mapState('moduleAdmin', ['selectedSchool']),
    schoolDataFields() {
      return [
        { title: 'School name', value: this.selectedSchool.schoolFullName },
        { title: 'School code', value: this.selectedSchool.schoolCode },
        { title: 'Phone', value: this.selectedSchool.phone },
        { title: 'Address', value: this.selectedSchool.address },
        { title: 'E-mail', value: this.selectedSchool.email },
        { title: 'Region', value: this.selectedSchool.region },
        { title: 'School level', value: this.selectedSchool.schoolLevel },
        { title: 'Socio economic group', value: this.selectedSchool.seGroup },
        { title: 'Transfer points', value: this.selectedSchool.transferPoints },
        { title: 'School specialty type', value: this.selectedSchool.schSpecialtyType },
        { title: 'Principal network', value: this.selectedSchool.principalNetwork },
        { title: 'School population gender', value: this.selectedSchool.schPopGender },
      ];
    },
  },
};
</script>

<style></style>
