const fieldRequired = (v) => (!!v && !!v.trim()) || errorMessages.FIELD_REQUIRED;

const validEmail = (v) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    v
  ) || errorMessages.EMAIL;

// Can only contain letters, spaces, ' and -
const strictAlpha = (v) => /^[a-zA-Z '\\-]+$/.test(v);

// Can only be empty or contain numbers, (), + and -
const intlPhoneNumber = (v) => !v || /^[0-9 ()+-]+$/.test(v) || errorMessages.PHONE_NUM;

// Can only contain letters, numbers, spaces and .
const alphanumericDotDash = (v) => /^[0-9a-zA-Z .-]+$/.test(v) || errorMessages.ALPHANUMERIC_DOT_DASH;

const positiveInteger = (v) => /^[0-9]\d*$/.test(v) || errorMessages.POSITIVE_INTEGER;

const valueRange = (v, min, max) => (v >= min && v <= max) || `Value must be between ${min} and ${max}`;

const errorMessages = {
  FIELD_REQUIRED: 'This field is required',
  PHONE_NUM: 'Can only contain 0-9, spaces and - ( ) +',
  EMAIL: 'Please enter a valid email address.',
  ALPHANUMERIC_DOT_DASH: 'Can only contain letters, numbers, spaces, - and .',
  POSITIVE_INTEGER: 'Can only be a number greater than zero',
};

export default {
  fieldRequired,
  validEmail,
  strictAlpha,
  intlPhoneNumber,
  alphanumericDotDash,
  positiveInteger,
  valueRange,
  errorMessages,
};
