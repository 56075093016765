<template>
  <v-card class="pt-0 d-flex" flat>
    <div
      :style="{ height: mobile ? '90vh' : '63vh' }"
      class="d-flex flex-column mt-0"
      style="width: 100%; overflow: hidden"
    >
      <div ref="fieldsElement">
        <v-row :class="mobile ? 'pa-2' : ''" no-gutters>
          <v-col class="pa-4">
            <AdsTextField
              v-model="filters.searchValue"
              hide-details="auto"
              label="Search school or region"
              placeholder="Search school or region"
              prepend-inner-icon="search"
              @blur="
                () => {
                  updateFilters();
                }
              "
            />
          </v-col>
        </v-row>
        <div>
          <div v-if="!mobile" class="d-flex flex-wrap px-5">
            <ChipFilter
              key="filter_1"
              v-model="benefitsModal"
              :items="typeBenefits"
              class="pa-1"
              heading="Benefits"
              name="Benefits"
              type="checkbox"
              @change="updateFilters"
            />
            <ChipFilter
              key="filter_2"
              v-model="levelModal"
              :items="schoolLevel"
              class="pa-1"
              heading="School level"
              name="School level"
              type="checkbox"
              @change="updateFilters"
            />
            <ChipFilter
              key="filter_3"
              v-model="sizeModal"
              :items="sizeSchool"
              class="pa-1"
              heading="School size"
              name="School size"
              type="checkbox"
              @change="updateFilters"
            />
            <ChipFilter
              key="filter_4"
              v-model="pointsModal"
              :items="typePoints"
              class="pa-1"
              heading="Transfer points"
              name="Transfer points"
              offset-x
              style="z-index: unset"
              type="checkbox"
              @change="updateFilters"
            />
            <ChipFilter
              key="filter_5"
              v-model="allowancesModal"
              :items="typeAllowances"
              class="pa-1"
              heading="Allowances"
              name="Allowances"
              offset-x
              style="z-index: unset"
              type="checkbox"
              @change="updateFilters"
            />
            <ChipFilter
              key="filter_6"
              v-model="typeModal"
              :items="schoolType"
              class="pa-1"
              heading="School type"
              name="School type"
              offset-x
              style="z-index: unset"
              type="checkbox"
              @change="updateFilters"
            />
          </div>
          <v-slide-group v-else-if="mobile" :show-arrows="false" class="ma-2 d-flex flex-wrap" hide-delimiters>
            <v-slide-item key="slide_1">
              <ChipFilter
                key="filter_1"
                v-model="benefitsModal"
                :items="typeBenefits"
                heading="Benefits"
                name="Benefits"
                type="checkbox"
                @change="updateFilters"
              />
            </v-slide-item>
            <v-slide-item key="slide_2">
              <ChipFilter
                key="filter_2"
                v-model="levelModal"
                :items="schoolLevel"
                heading="School type"
                name="School type"
                type="checkbox"
                @change="updateFilters"
              />
            </v-slide-item>
            <v-slide-item key="slide_3">
              <ChipFilter
                key="filter_3"
                v-model="sizeModal"
                :items="sizeSchool"
                heading="School size"
                name="School size"
                type="checkbox"
                @change="updateFilters"
              />
            </v-slide-item>
            <v-slide-item key="slide_4">
              <ChipFilter
                key="filter_4"
                v-model="pointsModal"
                :items="typePoints"
                heading="Transfer points"
                name="Transfer points"
                offset-x
                style="z-index: unset"
                type="checkbox"
                @change="updateFilters"
              />
            </v-slide-item>
            <v-slide-item key="slide_5">
              <ChipFilter
                key="filter_5"
                v-model="allowancesModal"
                :items="typeAllowances"
                heading="Allowances"
                name="Allowances"
                offset-x
                style="z-index: unset"
                type="checkbox"
                @change="updateFilters"
              />
            </v-slide-item>
            <v-slide-item key="slide_6">
              <ChipFilter
                key="filter_6"
                v-model="typeModal"
                :items="schoolType"
                heading="School Status"
                name="School Status"
                offset-x
                style="z-index: unset"
                type="checkbox"
                @change="updateFilters"
              />
            </v-slide-item>
          </v-slide-group>
          <div v-if="schoolResults.length > 0" class="px-6 pt-6 pb-4">
            <div class="d-flex justify-space-between align-end">
              <div class="default-text font-weight-bold">
                {{ schoolResults.length }}
                total results
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isUserSearch"
        :style="`border-top: 1px solid #ccc ${mobile ? 'width: 100vw' : ''}`"
        class="pa-0 pt-0 pb-0"
      >
        <VirtualScroller
          :height="scrollerHeight.toString()"
          :inFullScreen="true"
          :results="schoolResults"
          contentWidth="100%"
          @compareSchool="compareSchool"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
import { AdsTextField, ChipFilter } from '@nswdoe/doe-ui-core';
import { mapGetters, mapState } from 'vuex';
import { COLOURS, SIZES } from '@/constants';
import VirtualScroller from '@/components/SearchResults/VirtualScroller';
import { ref } from 'vue';
import { useElementSize } from '@vueuse/core';
import {
  MAJOR_BENEFITS_CHIP_FILTER_CODES,
  ALLOWANCES_CHIP_FILTER_CODES,
  CHIP_FILTER_SCHOOL_LEVELS,
  CHIP_FILTER_TRANSFER_POINTS,
  CHIP_FILTER_SCHOOL_SIZES,
  CHIP_FILTER_SCHOOL_TYPES,
} from '@/constants';
import { getBenefitChipFilterOptions } from '@/helpers/utils';

export default {
  name: 'AddSchool',
  components: {
    AdsTextField,
    ChipFilter,
    VirtualScroller,
  },
  props: {
    results: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tabs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      COLOURS: COLOURS,
      SIZES: SIZES,
      tab: null,
      menu: false,
      scrollerHeight: '0',
      tabBarHeight: '0',
      benefitsModal: [],
      filters: {
        isIncentiveOnly: false,
        isConnectedCommunityOnly: false,
        selectedBenefits: [],
        selectedLevel: [],
        selectedSize: [],
        selectedWorkType: [],
        selectedPoints: [],
        selectedAllowance: [],
        selectedType: [],
        searchValue: '',
      },
      sizeModal: [],
      typeModal: [],
      allowancesModal: [],
      levelModal: [],
      pointsModal: [],
      searchModal: '',
      clientHeight: document.documentElement.clientHeight,
      NAV_ITEMS: [{ tab: 'LIST VIEW' }, { tab: 'MAP VIEW' }],
      schoolLevel: CHIP_FILTER_SCHOOL_LEVELS,
      sizeSchool: CHIP_FILTER_SCHOOL_SIZES,
      typePoints: CHIP_FILTER_TRANSFER_POINTS,
      schoolType: CHIP_FILTER_SCHOOL_TYPES,
    };
  },
  computed: {
    ...mapState('moduleResults', { storeResults: 'results' }),
    ...mapGetters({
      isUserSearch: 'isUserSearch',
      numberOfCompare: 'numberOfCompare',
    }),
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    typeBenefits() {
      return getBenefitChipFilterOptions(MAJOR_BENEFITS_CHIP_FILTER_CODES, this.allBenefits);
    },
    typeAllowances() {
      return getBenefitChipFilterOptions(ALLOWANCES_CHIP_FILTER_CODES, this.allBenefits);
    },
    allBenefits() {
      const unifiedBenefitsCodes = this.results.flatMap((school) => school.benefits.benefitCodes);
      const uniqueBenefitsCodes = [...new Set(unifiedBenefitsCodes)];
      const uniqueBenefits = uniqueBenefitsCodes.map((benefitCode) => {
        return this.storeResults.benefitDetails.find((benefit) => {
          return benefit.benefitCode === benefitCode;
        });
      });
      return uniqueBenefits;
    },
    schoolResults() {
      let results = this.results;
      const filters = this.filters;
      if (filters.searchValue) {
        const filterRegion = filters.searchValue.toLowerCase();
        results = results.filter(
          (result) =>
            result.address.toLowerCase().indexOf(filterRegion) != -1 ||
            result.schoolFullName.toLowerCase().includes(filterRegion) ||
            result.region.toLowerCase().includes(filterRegion)
        );
      }
      if (filters.selectedPoints.length) {
        results = results.filter((result) => filters.selectedPoints.includes(result.transferPoints));
      }
      if (filters.selectedLevel.length) {
        const selectedLevels = filters.selectedLevel.map((schoolLevel) => schoolLevel.toLowerCase());
        results = results.filter((result) => selectedLevels.includes(result.schoolLevel.toLowerCase()));
      }
      if (filters.selectedSize.length) {
        const sortedSize = filters.selectedSize.sort((a, b) => b - a)[0];
        if (sortedSize > 500) {
          results = results.filter((result) => result.studentNumber > 500);
        } else results = results.filter((result) => result.studentNumber <= sortedSize);
      }
      if (filters.selectedAllowance.length) {
        const lowerCaseSelectedAllowance = filters.selectedAllowance.map((item) => item.toLowerCase());
        const schoolsWithBenefitsNames = results.map((school) => {
          const benefitsDetails = school.benefits.benefitCodes.map((code) =>
            this.storeResults.benefitDetails.find((benefit) => benefit.benefitCode === code)
          );
          return {
            ...school,
            benefitsDetails,
          };
        });
        results = schoolsWithBenefitsNames.filter((school) => {
          return school.benefitsDetails.some(
            (item) => lowerCaseSelectedAllowance.indexOf(item.name.toLowerCase()) != -1
          );
        });
      }
      if (filters.selectedBenefits.length) {
        const lowerCaseSelectedBenefits = filters.selectedBenefits.map((item) => item.toLowerCase());
        const schoolsWithBenefitsNames = results.map((school) => {
          const benefitsDetails = school.benefits.benefitCodes.map((code) =>
            this.storeResults.benefitDetails.find((benefit) => benefit.benefitCode === code)
          );
          return {
            ...school,
            benefitsDetails,
          };
        });
        results = schoolsWithBenefitsNames.filter((school) => {
          return school.benefitsDetails.some(
            (item) => lowerCaseSelectedBenefits.indexOf(item.name.toLowerCase()) != -1
          );
        });
      }
      if (filters.isIncentiveOnly) {
        results = results.filter((result) => result.isIncentive === true);
      }
      if (filters.isConnectedCommunityOnly) {
        results = results.filter((result) => result.isCC);
      }
      if (filters.selectedType.length) {
        const isIncentiveSchool = filters.selectedType.some((item) => item.toLowerCase() === 'incentive school');
        const isConnectedCommunitySchool = filters.selectedType.some(
          (item) => item.toLowerCase() === 'connected community school'
        );
        results = results.filter(
          (result) =>
            (result.isIncentive === true && isIncentiveSchool) || (result.isCC === true && isConnectedCommunitySchool)
        );
      }
      return results;
    },
  },
  created() {
    this.resetFilters();
  },
  watch: {
    benefitsModal(value, oldValue) {
      if (oldValue != null && value != oldValue) {
        this.filters.selectedBenefits = value;
        this.updateFilters();
      }
    },
    levelModal(value, oldValue) {
      if (value != oldValue) {
        this.filters.selectedLevel = value;
        this.updateFilters();
      }
    },
    sizeModal(value, oldValue) {
      if (value != oldValue) {
        this.filters.selectedSize = value;
        this.updateFilters();
      }
    },
    pointsModal(value, oldValue) {
      if (value != oldValue) {
        this.filters.selectedPoints = value;
        this.updateFilters();
      }
    },
    allowancesModal(value, oldValue) {
      if (value != oldValue) {
        this.filters.selectedAllowance = value;
        this.updateFilters();
      }
    },
    typeModal(value, oldValue) {
      if (value != oldValue) {
        this.filters.selectedType = value;
        this.updateFilters();
      }
    },
    fieldsHeight(val) {
      this.scrollerHeight = document.documentElement.clientHeight - this.getScrollerHeight(val);
    },
    tabNavHeight(val) {
      if (!this.tabBarHeight) {
        this.tabBarHeight = (document.documentElement.clientHeight + val).toString();
        this.$emit('scrollHeight', this.tabBarHeight);
      }
    },
  },
  methods: {
    compareSchool() {
      this.$emit('compareSchool');
    },
    getFilterName(filterKey) {
      const filtersOptions = [
        {
          key: 'selectedBenefits',
          value: 'Benefits',
        },
        {
          key: 'selectedLevel',
          value: 'school_level',
        },
        {
          key: 'selectedSize',
          value: 'school_size',
        },
        {
          key: 'selectedPoints',
          value: 'transfer_points',
        },
        {
          key: 'selectedAllowance',
          value: 'allowances',
        },
        {
          key: 'selectedType',
          value: 'school_type',
        },
      ];
      return filtersOptions.find((option) => option.key == filterKey).value;
    },
    updateFilters() {
      this.$store.commit('SET_IS_LOADING', true);
      this.$store.dispatch('moduleResults/updateFilters', this.filters);
      this.$store.commit('SET_IS_LOADING', false);
    },
    resetFilters() {
      this.$store.dispatch('moduleResults/updateFilters', {
        isIncentiveOnly: false,
        isConnectedCommunityOnly: false,
        selectedBenefits: [],
        selectedLevel: [],
        selectedSize: [],
        selectedWorkType: [],
        selectedPoints: [],
        selectedAllowance: [],
        selectedType: [],
        searchValue: '',
      });
    },
    showComparisonTableAction() {
      this.$router.push('/comparison');
    },
    closeMenu() {
      this.menu = false;
    },
    checkForBanner() {
      return this.mobile && this.isUserSearch;
    },
    getScrollerHeight(elHeight) {
      let bannerHeight = 300;
      if (this.mobile) {
        bannerHeight = 30;
      }
      const total = bannerHeight + elHeight;
      return total;
    },
  },
  emits: ['scrollHeight', 'tabHeight'],
  setup() {
    const fieldsElement = ref(null);
    const tabNavElement = ref(null);
    let fieldsWidth = 0;
    let fieldsHeight = 0;
    let tabNavWidth = 0;
    let tabNavHeight = 0;
    const setFields = () => {
      const { width, height } = useElementSize(fieldsElement);
      fieldsWidth = width;
      fieldsHeight = height;
    };
    const setTabNav = () => {
      const { width, height } = useElementSize(tabNavElement);
      tabNavWidth = width;
      tabNavHeight = height;
    };
    setFields();
    setTabNav();
    return {
      fieldsElement,
      tabNavElement,
      fieldsWidth,
      fieldsHeight,
      tabNavWidth,
      tabNavHeight,
    };
  },
};
</script>
<style scoped>
.default-text {
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
}

.mobile-text {
  font-size: 13px !important;
}

.label-text {
  font-size: 16px !important;
}

.mobile-label {
  font-size: 14px !important;
}
</style>
