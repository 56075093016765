import api from '@/api';

const moduleAdmin = {
  namespaced: true,
  state: {
    schools: [],
    benefits: [],
    selectedBenefit: null,
    selectedSchool: null,
    snackbar: {
      visible: false,
      message: null,
    },
    isLoading: false,
    error: {
      isError: false,
      message: null,
    },
  },
  mutations: {
    SET_SCHOOLS(state, schools) {
      state.schools = schools;
    },
    SET_SELECTED_SCHOOL(state, schoolData) {
      state.selectedSchool = schoolData;
    },
    RESET_SELECTED_SCHOOL(state) {
      state.selectedSchool = null;
    },
    SET_BENEFITS(state, benefits) {
      state.benefits = benefits;
    },
    SET_SELECTED_BENEFIT(state, benefitData) {
      state.selectedBenefit = benefitData;
    },
    RESET_SELECTED_BENEFIT(state) {
      state.selectedBenefit = null;
    },
    TOGGLE_SNACKBAR(state, visible) {
      state.snackbar.visible = visible;
    },
    SET_SNACKBAR_MESSAGE(state, message) {
      state.snackbar.message = message;
    },
    RESET_SNACKBAR(state) {
      state.snackbar = {
        visible: false,
        message: null,
      };
    },
    SET_ERROR(state, message) {
      state.error.isError = true;
      state.error.message = message;
    },
    RESET_ERROR(state) {
      state.error = {
        isError: false,
        message: null,
      };
    },
    SET_IS_LOADING(state, value) {
      state.isLoading = value;
    },
  },
  actions: {
    async getSchools({ commit, rootState }, payload?) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true });
      }
      try {
        const schools = await api.school.getSchools(payload);
        commit('SET_SCHOOLS', schools);
      } catch (err) {
        // TODO handle error
        console.error(err);
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true });
        }
      }
    },
    async createUpdateDeleteSchool({ commit, state }, payload) {
      if (!state.isLoading) {
        commit('SET_IS_LOADING', true);
        try {
          const school = await api.school.createUpdateDeleteSchool(payload.formData);
          commit('SET_IS_LOADING', false);
          switch (payload.type) {
            case 'create':
              commit('SET_SNACKBAR_MESSAGE', `${school.schoolFullName} was successfully created.`);
              break;
            case 'update':
              commit('SET_SNACKBAR_MESSAGE', `${school.schoolFullName} was successfully updated.`);
              break;
            case 'delete':
              commit('SET_SNACKBAR_MESSAGE', `${school.schoolFullName} was successfully deleted.`);
              break;
          }
          commit('TOGGLE_SNACKBAR', true);
        } catch (err) {
          commit('SET_IS_LOADING', false);
          commit('SET_ERROR', err.message);
        }
      }
    },
    async createUpdateDeleteBenefit({ commit, state }, payload) {
      if (!state.isLoading) {
        commit('SET_IS_LOADING', true);
        try {
          const benefit = await api.benefits.createUpdateDeleteBenefit(payload.formData);
          commit('SET_IS_LOADING', false);
          let benefitDetails = benefit;
          if (Array.isArray(benefit)) {
          benefitDetails =
            benefit.find((benefitObj) => {
              return Object.hasOwn(benefitObj, 'name');
            }) || {};
          }
          const { name } = benefitDetails;
          switch (payload.type) {
            case 'create':
              commit('SET_SNACKBAR_MESSAGE', `Benefit ${name} was successfully created.`);
              break;
            case 'update':
              commit('SET_SNACKBAR_MESSAGE', `Benefit ${name} was successfully updated.`);
              break;
            case 'delete':
              commit('SET_SNACKBAR_MESSAGE', `Benefit ${name} was successfully deleted.`);
              break;
          }
          commit('TOGGLE_SNACKBAR', true);
        } catch (err) {
          commit('SET_IS_LOADING', false);
          commit('SET_ERROR', err.message);
        }
      }
    },
    async uploadSchoolsCsv({ commit }, schoolsCsv) {
      try {
        const response = await api.school.uploadSchoolsCsv(schoolsCsv);
        if (response.success && !response.errorItems) {
          commit('SET_SNACKBAR_MESSAGE', `Schools were successfully updated.`);
          commit('TOGGLE_SNACKBAR', true);
          return response;
        } else {
          return response;
        }
      } catch (err) {
        throw { err };
      }
    },
    async uploadBenefitsCsv({ commit }, payload) {
      try {
        const { csvSchema, csvFile } = payload;
        const response = await api.benefits.uploadBenefitsCsv(csvSchema, csvFile);
        if (response.success && !response.errorItems) {
          commit('SET_SNACKBAR_MESSAGE', `Benefits were successfully updated.`);
          commit('TOGGLE_SNACKBAR', true);
          return response;
        } else {
          return response;
        }
      } catch (err) {
        throw { err };
      }
    },
    async getBenefits({ commit, rootState }) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true });
      }
      try {
        const benefits = await api.benefits.getBenefits();
        commit('SET_BENEFITS', benefits);
      } catch (err) {
        // TODO handle error
        console.error(err);
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, { root: true });
        }
      }
    },
  },
};
export default moduleAdmin;
