import axiosInstance, { get, post } from '@/api/http';

export const getEntity = async (name) => {
  try {
    const response = await get(`${name}`);
    if (response.status === 200 || response.statusText === 'OK') {
      return response.data;
    }
  } catch (err) {
    throw { message: 'Something went wrong, please try again later.' };
  }
};

export const createUpdateDeleteEntity = async (name, data) => {
  try {
    const response = await post(`${name}`, data);
    if (response.status === 200 || response.statusText === 'OK') {
      return response.data.length === 1 ? response.data[0] : response.data;
    }
  } catch (err) {
    throw { message: err.response?.data || 'Something went wrong, please try again later.' };
  }
};

export const uploadEntityCsv = async (url, csvSchema, data) => {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url,
      headers: {
        'Accept': '*/*',
        // 'Content-Type': 'multipart/form-data',
        'Csv-Schema': csvSchema,
      },
      data,
    });
    if (response.status === 200 || response.statusText === 'OK') {
      return response.data;
    }
  } catch (err) {
    throw { message: err.response?.data || 'Something went wrong, please try again later.' };
  }
};
