import api from '@/api/modules/school';

const moduleResults = {
  namespaced: true,
  state: {
    results: {
      benefitDetails: [],
      schools: [],
      selectedSchool: undefined,
    },
    filters: {
      isIncentiveOnly: false,
      isConnectedCommunityOnly: false,
      selectedBenefits: [],
      selectedLevel: [],
      selectedSize: [],
      selectedWorkType: [],
      selectedPoints: [],
      selectedAllowance: [],
      selectedType: [],
      searchValue: '',
    },
  },
  getters: {
    results: (state) => {
      return state.results;
    },
    filter: (state) => state.filters,
  },
  mutations: {
    SET_BENEFIT_DETAILS(state, result) {
      state.results.benefitDetails = result;
    },
    SET_SCHOOLS(state, result) {
      state.results.schools = result;
    },
    SET_SELECTED_SCHOOL(state, result) {
      state.results.selectedSchool = result;
    },
    SET_RESULTS(state, result) {
      state.results = result;
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
  },
  actions: {
    fetchResults({ commit, rootState }, formValues) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true });
      }
      api
        .getSchoolsWithCalculatedBenefits(formValues)
        .then((response) => {
          commit('SET_BENEFIT_DETAILS', response.benefitDetails);
          commit('SET_SCHOOLS', response.schools);
        })
        .finally(() => {
          if (rootState.isLoading) {
            commit('SET_IS_LOADING', false, { root: true });
          }
        });
    },
    fetchSelectedResult({ commit, rootState }, formValues) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true });
      }
      commit('SET_SELECTED_SCHOOL', undefined);
      api
        .getSchoolsWithCalculatedBenefits(formValues)
        .then((response) => {
          commit('SET_BENEFIT_DETAILS', response.benefitDetails);
          commit('SET_SELECTED_SCHOOL', response.schools?.length === 1 ? response.schools[0] : undefined);
        })
        .finally(() => {
          if (rootState.isLoading) {
            commit('SET_IS_LOADING', false, { root: true });
          }
          commit('SET_SHOWMODAL', true, { root: true });
        });
    },
    resetResults({ commit }) {
      commit('SET_RESULTS', {
        benefitDetails: [],
        schools: [],
        selectedSchool: undefined,
      });
    },
    updateFilters({ commit }, filters) {
      commit('SET_FILTERS', filters);
    },
  },
};
export default moduleResults;
