<template>
  <v-form ref="schoolForm" v-model="isFormValid" v-bind="$attrs" @submit.prevent="updateSchool">
    <v-row>
      <v-col cols="12" sm="6">
        <AdsTextField
          v-model="schoolCode"
          label="School code"
          type="number"
          :rules="[rules.fieldRequired]"
          persistent-placeholder
        ></AdsTextField>
      </v-col>
      <v-col cols="12" sm="6">
        <AdsTextField
          v-model="schoolFullName"
          label="School Full Name"
          :rules="[rules.fieldRequired, rules.alphanumericDotDash]"
          persistent-placeholder
        ></AdsTextField>
      </v-col>
      <v-col cols="12" sm="6">
        <AdsTextField
          v-model="address"
          label="Address"
          :rules="[rules.fieldRequired]"
          persistent-placeholder
        ></AdsTextField>
      </v-col>
      <v-col cols="12" sm="6">
        <AdsTextField
          v-model="lat"
          label="Latitude"
          type="number"
          :rules="[rules.fieldRequired, () => rules.valueRange(lat, -90, 90)]"
          persistent-placeholder
        ></AdsTextField>
      </v-col>
      <v-col cols="12" sm="6">
        <AdsTextField
          v-model="lng"
          label="Longitude"
          type="number"
          :rules="[rules.fieldRequired, () => rules.valueRange(lng, -180, 180)]"
          persistent-placeholder
        ></AdsTextField>
      </v-col>
      <v-col cols="12" sm="6">
        <AdsSelect
          v-model.trim="schoolLevel"
          label="School Level"
          persistent-placeholder
          :rules="[rules.fieldRequired]"
          required
          :items="schoolLevelItems"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <AdsTextField
          v-model="email"
          label="Email"
          type="email"
          :rules="[rules.fieldRequired, rules.validEmail]"
          persistent-placeholder
        ></AdsTextField>
      </v-col>
      <v-col cols="12" sm="6">
        <AdsTextField
          v-model="phone"
          label="Phone"
          :rules="[rules.fieldRequired, rules.intlPhoneNumber]"
          persistent-placeholder
        ></AdsTextField>
      </v-col>
      <v-col cols="12" sm="6">
        <AdsTextField
          v-model="transferPoints"
          label="Transfer Points"
          type="number"
          :rules="[rules.fieldRequired, rules.positiveInteger]"
          persistent-placeholder
        ></AdsTextField>
      </v-col>
      <v-col cols="12" sm="6">
        <AdsTextField
          v-model="seGroup"
          label="Socio-economic group"
          type="number"
          step="1"
          :rules="[rules.positiveInteger]"
          persistent-placeholder
        ></AdsTextField>
      </v-col>
      <v-col cols="12" sm="6">
        <AdsTextField
          v-model="studentNumber"
          label="Student Number"
          type="number"
          step="1"
          :rules="[rules.positiveInteger]"
          persistent-placeholder
        ></AdsTextField>
      </v-col>
      <v-col cols="12" sm="6">
        <AdsTextField
          v-model="region"
          label="Region"
          :rules="[rules.fieldRequired, rules.alphanumericDotDash]"
          persistent-placeholder
        ></AdsTextField>
      </v-col>
      <v-col cols="12" sm="6">
        <AdsTextField
          v-model="principalNetwork"
          label="Principal network"
          :rules="[rules.fieldRequired, rules.alphanumericDotDash]"
          persistent-placeholder
        ></AdsTextField>
      </v-col>
      <v-col cols="12" sm="6">
        <AdsTextField
          v-model="schSpecialtyType"
          label="School specialty type"
          :rules="[rules.fieldRequired, rules.alphanumericDotDash]"
          persistent-placeholder
        ></AdsTextField>
      </v-col>
      <v-col cols="12" sm="6">
        <AdsSelect
          v-model="schPopGender"
          label="School population gender"
          :rules="[rules.fieldRequired]"
          :items="['Coed School', 'Boys School', 'Girls School']"
          persistent-placeholder
        ></AdsSelect>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <fieldset>
          <legend>Is incentive?</legend>
          <v-radio-group class="mt-0" v-model="isIncentive" mandatory row>
            <v-radio
              v-for="(option, index) in radioButtonOptions"
              :key="index"
              :label="option.label"
              :value="option.value"
            />
          </v-radio-group>
        </fieldset>
      </v-col>
      <v-col cols="12" sm="6">
        <fieldset>
          <legend>Hot climatic allowance?</legend>
          <v-radio-group class="mt-0" v-model="isHot" mandatory row>
            <v-radio
              v-for="(option, index) in radioButtonOptions"
              :key="index"
              :label="option.label"
              :value="option.value"
            />
          </v-radio-group>
        </fieldset>
      </v-col>
      <v-col cols="12" sm="6">
        <fieldset>
          <legend>Cold climatic allowance?</legend>
          <v-radio-group class="mt-0" v-model="isCold" mandatory row>
            <v-radio
              v-for="(option, index) in radioButtonOptions"
              :key="index"
              :label="option.label"
              :value="option.value"
            />
          </v-radio-group>
        </fieldset>
      </v-col>
      <v-col cols="12" sm="6">
        <fieldset>
          <legend>Late opening allowance?</legend>
          <v-radio-group class="mt-0" v-model="isLateOpening" mandatory row>
            <v-radio
              v-for="(option, index) in radioButtonOptions"
              :key="index"
              :label="option.label"
              :value="option.value"
            />
          </v-radio-group>
        </fieldset>
      </v-col>
      <v-col cols="12" sm="6">
        <fieldset>
          <legend>Demonstration lesson allowance?</legend>
          <v-radio-group class="mt-0" v-model="isDemonstration" mandatory row>
            <v-radio
              v-for="(option, index) in radioButtonOptions"
              :key="index"
              :label="option.label"
              :value="option.value"
            />
          </v-radio-group>
        </fieldset>
      </v-col>
      <v-col cols="12" sm="6">
        <fieldset>
          <legend>Yancowinna allowance?</legend>
          <v-radio-group class="mt-0" v-model="isYancowinna" mandatory row>
            <v-radio
              v-for="(option, index) in radioButtonOptions"
              :key="index"
              :label="option.label"
              :value="option.value"
            />
          </v-radio-group>
        </fieldset>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { AdsSelect, AdsTextField } from '@nswdoe/doe-ui-core';

import validators from './formValidators';

import { mapState, mapActions } from 'vuex';

export default {
  name: 'EditSchool',
  components: { AdsSelect, AdsTextField },
  data() {
    return {
      schoolCode: null,
      schoolFullName: null,
      email: null,
      phone: null,
      schoolLevel: null,
      transferPoints: null,
      studentNumber: null,
      address: null,
      region: null,
      isIncentive: false,
      seGroup: null,
      isHot: false,
      isCold: false,
      isLateOpening: false,
      isDemonstration: false,
      isYancowinna: false,
      principalNetwork: null,
      schSpecialtyType: null,
      schPopGender: null,
      lat: null,
      lng: null,
      isFormValid: false,
      rules: {
        alphanumericDotDash: validators.alphanumericDotDash,
        fieldRequired: validators.fieldRequired,
        strictAlpha: validators.strictAlpha,
        validEmail: validators.validEmail,
        intlPhoneNumber: validators.intlPhoneNumber,
        positiveInteger: validators.positiveInteger,
        valueRange: validators.valueRange,
      },
      schoolLevelItems: [
        'Primary School',
        'Central/Community School',
        'Schools for Specific Purposes',
        'Infants School',
        'Environmental Education Centre',
        'Secondary School',
        'Other School',
      ],
      radioButtonOptions: [
        {
          value: true,
          label: 'Yes',
        },
        {
          value: false,
          label: 'No',
        },
      ],
    };
  },
  computed: {
    ...mapState('moduleAdmin', ['selectedSchool', 'isLoading', 'error']),
    isEditSchoolRoute() {
      return this.$route.name === 'editSchool';
    },
  },
  methods: {
    ...mapActions('moduleAdmin', ['createUpdateDeleteSchool']),
    async updateSchool() {
      let operationType = 'update';
      let formData = {
        PK: this.selectedSchool.PK,
        schoolCode: this.schoolCode,
        schoolFullName: this.schoolFullName,
        email: this.email,
        phone: this.phone,
        schoolLevel: this.schoolLevel,
        transferPoints: this.transferPoints,
        studentNumber: this.studentNumber,
        address: this.address,
        region: this.region,
        isIncentive: this.isIncentive,
        seGroup: this.seGroup,
        isHot: this.isHot,
        isCold: this.isCold,
        isLateOpening: this.isLateOpening,
        isDemonstration: this.isDemonstration,
        isYancowinna: this.isYancowinna,
        principalNetwork: this.principalNetwork,
        schSpecialtyType: this.schSpecialtyType,
        schPopGender: this.schPopGender,
        lat: this.lat,
        lng: this.lng,
      };
      await this.createUpdateDeleteSchool({ formData, type: operationType });
      if (!this.error.isError) {
        this.goToPreviousRoute();
      }
    },
    goToPreviousRoute() {
      this.$router.go(-1);
    },
  },
  watch: {
    isFormValid(value) {
      this.$emit('update-form-validity', value);
    },
  },
  created() {
    this.schoolCode = this.selectedSchool.schoolCode?.toString();
    this.schoolFullName = this.selectedSchool.schoolFullName;
    this.email = this.selectedSchool.email;
    this.phone = this.selectedSchool.phone;
    this.schoolLevel = this.selectedSchool.schoolLevel;
    this.transferPoints = this.selectedSchool.transferPoints?.toString();
    this.studentNumber = this.selectedSchool.studentNumber?.toString();
    this.address = this.selectedSchool.address;
    this.region = this.selectedSchool.region;
    this.seGroup = this.selectedSchool.seGroup?.toString();
    this.isHot = this.selectedSchool.isHot ?? false;
    this.isCold = this.selectedSchool.isCold ?? false;
    this.isLateOpening = this.selectedSchool.isLateOpening ?? false;
    this.isDemonstration = this.selectedSchool.isDemonstration ?? false;
    this.isYancowinna = this.selectedSchool.isYancowinna ?? false;
    this.principalNetwork = this.selectedSchool.principalNetwork;
    this.schSpecialtyType = this.selectedSchool.schSpecialtyType;
    this.schPopGender = this.selectedSchool.schPopGender;
    this.lat = this.selectedSchool.lat?.toString();
    this.lng = this.selectedSchool.lng?.toString();
    this.isIncentive = this.selectedSchool.isIncentive ?? false;
  },
  emits: ['update-form-validity'],
};
</script>

<style scoped lang="scss">
fieldset {
  border: none;
}
</style>
