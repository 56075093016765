<template>
  <div>
    <Banner
      class="mb-3"
      :heading="selectedSchool.schoolFullName"
      :subtitle="`Total benefits up to $${selectedSchool.benefits?.total.toLocaleString() ?? 0}`"
      icon="mdi-school-outline"
      defaultBackground2
    />
    <v-container class="my-10 px-10">
      <v-row class="mb-10" justify="center" no-gutters>
        <v-col class="d-flex justify-space-between" cols="12" sm="9">
          <AdsButton tertiary buttonText="Back to schools" icon="mdi-arrow-left" @click="goToPreviousRoute" />
          <div>
            <AdsButton
              color="primary-red"
              secondary
              buttonText="Delete"
              icon="mdi-delete-outline"
              @click="showDeleteDialog = true"
            />
            <AdsButton
              v-if="editSchool"
              class="ml-4"
              primary
              buttonText="Save changes"
              icon="mdi-tray-arrow-down"
              :disabled="!isFormValid"
              type="submit"
              :loading="isLoading"
              @click="submitSchoolForm"
            />
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-col cols="12" sm="9">
          <v-card class="pa-3">
            <v-card-title class="pa-6 text-h4">
              <span class="headline primary-blue--text">School details</span>
              <v-spacer></v-spacer>
              <div class="d-flex align-center">
                <label for="editSwitch" class="mr-4 body-1">Edit</label>
                <v-switch id="editSwitch" v-model="editSchool" />
              </div>
            </v-card-title>
            <EditSchool v-if="editSchool" ref="editSchool" class="pa-6" @update-form-validity="isFormValid = $event" />
            <SchoolReadOnly v-else class="pl-6 pb-6" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <DeleteDialog :school="selectedSchool" :showDialog="showDeleteDialog" @close="showDeleteDialog = false" />
  </div>
</template>

<script>
import { AdsButton, Banner } from '@nswdoe/doe-ui-core';
import SchoolReadOnly from './SchoolReadOnly.vue';
import EditSchool from './EditSchool.vue';
import DeleteDialog from '@/components/UI/DeleteDialog.vue';

import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'SchoolForm',
  components: { AdsButton, Banner, EditSchool, SchoolReadOnly, DeleteDialog },
  data() {
    return {
      editSchool: false,
      isFormValid: false,
      showDeleteDialog: false,
    };
  },
  computed: {
    ...mapState('moduleAdmin', ['selectedSchool', 'isLoading']),
  },
  methods: {
    ...mapMutations('moduleAdmin', ['RESET_SELECTED_SCHOOL']),
    ...mapActions('moduleAdmin', ['getSchools', 'createUpdateDeleteSchool']),
    goToPreviousRoute() {
      this.$router.go(-1);
    },
    submitSchoolForm() {
      this.$refs.editSchool.updateSchool();
    },
  },
  async deleteSchool() {
    await this.createUpdateDeleteSchool({ formData: { ...this.selectedSchool, active: false }, type: 'delete' });
    this.deleteDialog = false;
  },
  beforeRouteLeave(to, from, next) {
    this.RESET_SELECTED_SCHOOL();
    next();
  },
};
</script>

<style scoped lang="scss">
.button--delete {
  color: $ads-primary-red !important;
}
:deep(.banner .appTitle) {
  font-size: 34px;
}
</style>
